import './config/envConfig';
import { autoLoadModules } from 'react-core/config/utils';

autoLoadModules()
  .then(() => import('./setup'))
  .then(({ default: setup }) => {
    if (['complete', 'loaded', 'interactive'].includes(document.readyState) && document.body)
      setup();
    else
      window.addEventListener('DOMContentLoaded', setup, false);
  });
